
import { defineComponent, ref } from "vue";

import firebase from "firebase";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import "firebase/auth";

import Button from "@/components/Button/Button.vue";
import PillButton from "@/components/PillButton/PillButton.vue";
import fetchAdapter from "@/toolkit/fetchAdapter";
import { getToday } from "@/toolkit/utils";
import { addExpense, getCurrentDayTotal } from "@/toolkit/utils/firebase";
import AmountInput from "@/components/AmountInput.vue";
import { spendTypes, defaultCommonSpendTypes } from "./spendTypes";

const db = firebase.firestore();

const initialData = {
  secretCode: "",
  showLogin: false,
  selectedSpendType: "Eating Out",
  spendDate: getToday().today,
  amount: "",
  payee: "",
  forWhat: "",
  paymentType: "CC",
  commonCategories: defaultCommonSpendTypes,
  isReimbursement: false
};

type Data = typeof initialData & {
  userName: string;
  didSubmit: boolean;
  isSubmitting: boolean;
  isMe: boolean;
  showLogin: boolean;
  uid: string;
  dailyTotal: string;
  submitted: boolean;
  hasError: boolean;
  rerouteCountdownId: number | null;
};

const defaultUiConfig: firebaseui.auth.Config = {
  signInFlow: "popup",
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
  tosUrl: "https://google.com",
  privacyPolicyUrl: "https://google.com"
};

export default defineComponent({
  components: {
    Button,
    PillButton,
    AmountInput
  },
  setup() {
    const uiRef = ref<firebaseui.auth.AuthUI | null>(null);

    return { uiRef, spendTypes, defaultCommonSpendTypes };
  },
  created() {
    firebase.auth().onAuthStateChanged(async firebaseUser => {
      if (firebaseUser) {
        const { uid } = firebaseUser;
        this.uid = uid;
        this.$store.dispatch("setUid", { uid });
        try {
          const user = await db.collection("users").doc(uid);
          const currentUser = await user.get();
          const data = currentUser.data();
          if (data) {
            this.setDailyTotal();
            this.isMe = data.can_view;
            // TODO - add common_categories to db
            /*
              {
                [category]: number
              }
              Then sort by most occurring category.
              Only display top 5.
              Handle in addExpenses.ts.
              Will need to create a method to parse through object returned from DB.
            */
            this.commonCategories = data.common_categories ?? defaultCommonSpendTypes;
            const name = firebaseUser.displayName;
            if (name) {
              this.userName = name;
            }
            return;
          }
        } catch (error) {
          if (this.uid.length) {
            firebase.analytics().logEvent("imposter_tried_to_login");
            firebase.analytics().setUserProperties({
              user_name: firebaseUser.displayName,
              user_email: firebaseUser.email
            });
            const mailerData: MailerPRIVATE_ImposterBody = {
              type: "imposterNotif",
              googleData: firebaseUser
            };
            this.sendMail(mailerData);
            this.reroute();
            return;
          }
        }
      }
      this.isMe = false;
      this.renderAuthUI();
    });
  },
  data(): Data {
    return {
      ...initialData,
      userName: "",
      didSubmit: false,
      isSubmitting: false,
      isMe: false,
      uid: "",
      dailyTotal: "0",
      submitted: false,
      hasError: false,
      rerouteCountdownId: null
    };
  },
  async beforeUnmount() {
    if (this.rerouteCountdownId !== null) {
      clearTimeout(this.rerouteCountdownId);
    }
    // eslint-disable-next-line no-unused-expressions
    await this.uiRef?.delete();
    this.uiRef = null;
  },
  methods: {
    async renderAuthUI(): Promise<void> {
      if (this.uiRef) {
        await this.uiRef.delete();
        this.uiRef = null;
      }
      if (!this.uiRef) {
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        this.uiRef = new firebaseui.auth.AuthUI(firebase.auth());
        this.uiRef.start("#firebaseAuth", {
          ...defaultUiConfig,
          callbacks: {
            signInSuccessWithAuthResult: (authResult: any): boolean => {
              const { uid } = authResult.user;
              this.uid = uid;
              db.collection("users")
                .doc(uid)
                .get()
                .then((): void => {
                  const usersRef = db.collection("users");
                  usersRef.doc(authResult.user.uid).set({
                    can_view: true
                  });
                })
                .catch((): void => {
                  console.log("You should not be here");
                });
              return false;
            }
          }
        });
      }
    },
    handlePillSelect(type: string): void {
      this.selectedSpendType = type;
    },
    setDailyTotal(): void {
      if (this.uid != null) {
        getCurrentDayTotal(this.uid).then(total => {
          this.dailyTotal = total;
        });
      }
    },
    setAmount(newAmount: string): void {
      this.amount = newAmount;
    },
    reroute() {
      firebase.auth().signOut();
      this.hasError = true;
      this.clear();
      this.rerouteCountdownId = setTimeout(() => {
        this.$router.push("/");
      }, 2000);
    },
    check(): void {
      if (
        this.secretCode === process.env.VUE_APP_PRIVATE_PASS_0 ||
        this.secretCode === process.env.VUE_APP_PRIVATE_PASS_1
      ) {
        this.showLogin = true;
        this.hasError = false;
        if (this.uiRef) return;
        this.renderAuthUI();
      } else {
        firebase.analytics().logEvent("potential_imposter");
        firebase.analytics().setUserProperties({ user_input: this.secretCode });
        const data: MailerPRIVATE_ImposterBody = {
          type: "imposterNotif",
          secretCode: this.secretCode
        };
        this.sendMail(data);
        this.reroute();
      }
    },
    async clear() {
      firebase.auth().signOut();
      // eslint-disable-next-line no-unused-expressions
      await this.uiRef?.delete();
      this.uiRef = null;
      this.reset();
    },
    async submit() {
      this.isSubmitting = true;
      const data: MailerPRIVATE_ExpenseBody = {
        type: "expenseReport",
        category: this.selectedSpendType,
        date: this.spendDate,
        amount: this.isReimbursement ? `-${this.amount}` : this.amount,
        payee: this.payee,
        memo: this.forWhat,
        paymentType: this.paymentType,
        submittedBy: this.userName
      };
      await Promise.all([addExpense(this.uid, data), this.sendMail(data)]);
      this.didSubmit = true;
      this.isSubmitting = false;
    },
    async sendMail(data: MailerPRIVATE_ExpenseBody | MailerPRIVATE_ImposterBody): Promise<void> {
      await fetchAdapter("/.netlify/functions/mailer", {
        body: JSON.stringify(data)
      });
    },
    reset() {
      this.setDailyTotal();
      Object.assign(this.$data, { ...initialData });
    }
  }
});
