
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    amount: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleAmountChange(key: string): void {
      if (Number.isNaN(Number(key)) && key !== 'Backspace') return;
      const split = this.amount.replace('.', '').split('');
      if (key === 'Backspace') {
        split.pop();
      } else {
        split.push(key);
      }
      split.splice(-2, 0, '.');
      if (split[0] === '0') {
        split.shift();
      }
      this.$emit('setAmount', split.join(''));
    },
  }
});
