
import { defineComponent } from 'vue';
import Button from '@/components/Button/Button.vue';

export default defineComponent({
  setup(props) {
    return { props };
  },
  components: {
    Button
  }
});
